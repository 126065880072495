import Seo from "../components/Seo";
import React from "react";
import PageWrapper from "../components/PageWrapper";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Button, MainTitle, Section, Text, Title } from "../components/Core";
import { navigate } from "gatsby";
import Link from "gatsby-link";
import heroImg from "./../assets/image/jpeg/hero-planning.jpg";
import privateRoads from "./../assets/image/png/private-roads-about.png";
import privateRoadsSection from "./../assets/image/jpeg/private-roads-section.jpg";
import fullWidthPrivateRoadsBackground from './../assets/image/jpeg/full-width-private-roads.jpg';
import styled from "styled-components";

import icon1 from './../assets/image/png/icon-private-roads-1.png';
import icon2 from './../assets/image/png/icon-private-roads-2.png';

import prm from './../assets/image/jpeg/private-road-motive.jpg';

const PrivateRoads = () => {

    const Box = styled.div`
        background-color: #fff;
        padding: 50px;
        border-radius: 50px;
        box-shadow: 1px 1px 6px 1px rgba(0,0,0,0.20);
        @media (max-width: 991px) {
            padding: 25px;
            .first-img {
                margin-bottom: 25px;
            }
            .second-img {
                margin-top: 25px;
            }
        }
    `;

    const AbsoluteTitle = styled(Title)`
        position: absolute;
        color: #fff;
        top: 120px;
        left: 15px;
        @media (max-width: 575px) { 
            line-height: 1;
            font-size: 2rem;
        }
        @media (min-width: 576px) { 
            top: 30%;
            left: calc((100vw - 525px) / 2);
            line-height: 1;
            font-size: 2.4rem;
        }
        @media (min-width: 768px) { 
            top: 30%;
            left: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            top: 30%;
            left: calc((100vw - 945px) / 2);
        }

        @media (min-width: 1200px) {
            top: 200px;
            font-size: 60px;
            line-height: 70px;
            left: calc((100vw - 1125px) / 2);
        }
    `;

    const StyledText = styled(Text)`
        position: absolute;
        color: #fff;
        top: 200px;
        left: 15px;
        font-size: 18px;
        line-height: 1.1;
        @media (min-width: 576px) { 
            line-height: 1.5;
            top: 53%;
            left: calc((100vw - 525px) / 2);
            font-size: 24px;
            line-height: 1;
        }
        @media (min-width: 768px) { 
            top: 53%;
            left: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            top: 53%;
            left: calc((100vw - 945px) / 2);
        }

        @media (min-width: 1200px) {
            top: 350px;
            font-size: 28px;
            line-height: 38px;
            left: calc((100vw - 1125px) / 2);
        }
        color: #fff !important;
    `;

    const NarrowWrapper = styled.div`
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: 0 0 100px 0;
        p {
            color: #fff;
            text-align: left;
            margin-bottom: 10px;
        }
        @media (max-width: 1400px) {
            width: 100%;
        }
        @media (max-width: 991px) {
            margin-bottom: 50px;
        }
        padding-left: 15px;
        @media (min-width: 576px) { 
            padding-left: calc((100vw - 525px) / 2);
            padding-right: calc((100vw - 525px) / 2);
            p {
                font-size: 28px;
            }
        }
        @media (min-width: 768px) { 
            padding-left: calc((100vw - 705px) / 2);
            padding-right: calc((100vw - 705px) / 2);
        }
        @media (min-width: 992px) { 
            padding-left: calc((100vw - 945px) / 2);
            padding-right: calc((100vw - 945px) / 2);
        }

        @media (max-width: 991px) { 
            padding-top: 0;
            padding-bottom: 40px;
        }

        @media (min-width: 1200px) {
            padding-left: calc((100vw - 1125px) / 2);
            padding-right: 0;
        }
    `;

    const PinkTitle = styled(Title)`
        color: #db118b;
        margin-top: 100px;
        text-align: left;
        @media (max-width: 991px) {
            margin-top: 20px;
        }
    `;

    const RadiusImg = styled.img`
        border-radius: 20px;
    `;

    const FullWidthSection = styled(Section)`
        background: url(${fullWidthPrivateRoadsBackground});
        background-size: cover;
        background-repear: no-repeat;
    `;

    const NarrowBox = styled(Box)`
        max-width: 600px;
        display: block;
        margin: 100px auto;
        text-align: center;
        @media (max-width: 991px) {
            margin: 0 auto;
        }
    `;

    const SmallParagraph = styled(Text)`
        font-size: 18px;
        line-height: 30px;
    `;

    const GradientSection = styled(Section)`
        position: relative;
        background: linear-gradient(90deg, rgba(206,3,144,1) 0%, rgba(252,58,76,1) 100%);
        padding: 100px 0 0 0;
        h2, p, a {
            color: #fff !important;
        }
        .container {
            position: relative;
            padding-bottom: 100px;
        }
    `;

    const PinkContainer = styled(Section)`
        background-color: #fdf5f9;
        background-image: url(${prm});
        background-repeat: no-repeat;
        background-position: 0 100%;
        background-size: 20%;
    `;

    const HeroImg = styled.img`
        @media (max-width: 575px) { 
            height: 300px;
            width: auto !important;
            position: relative;
            left: -160px;
            filter: brightness(0.8);
        }
    `;

    return(
        <>
            <Seo page="private-roads"/>
            <div className="hero-wayleaves">
                <HeroImg src={heroImg} className="w-100 mt-10" />
                <AbsoluteTitle>
                    Landowners/<br/>Private Roads
                </AbsoluteTitle>
                <StyledText>
                    If you have received a wayleave request<br/>
                    from us, this means that there is part of our<br/>
                    network design that falls within your land<br/>
                    boundaries. This could be to deliver service<br/>
                    to a neighbour or to unlock an entire<br/>
                    section of a town or city.
                </StyledText>
            </div>
            <PageWrapper>
                <Row className="justify-content-center text-center dark-bg">
                    <Col xl="6">
                        <NarrowWrapper>
                            <PinkTitle>What is a wayleave</PinkTitle>
                            <Text>
                                A wayleave is an agreement between
                                the freeholder and a supplier, in this
                                case Netomnia. This gives the supplier
                                the right to access the property and
                                carry out pre agreed works to install
                                the supplier's infrastructure. The
                                Wayleave also allows the supplier to
                                maintain the infrastructure under prior
                                notice to the freeholder.
                            </Text>
                        </NarrowWrapper>
                    </Col>
                    <Col xl="1"></Col>
                    <Col xl="5" className="flex-end">
                        <img src={privateRoads} className="w-100 first-img"/>
                    </Col>
                </Row>
                <PinkContainer>
                    <Container>
                        <Row className="mb-5 pb-5">
                            <Col xl="6 pr-5">
                                <Title variant="news">
                                    Why is access required to your land?
                                </Title>
                                <Text>
                                    At Netomnia, we are a full fibre broadband network
                                    operator. We are rolling out a full fibre network across
                                    the entire UK.
                                </Text>
                                <Text>
                                    To facilitate the build of our network, we will need
                                    access to your land, whether to maintain existing
                                    infrastructure or install new equipment.
                                </Text>
                                <Text>
                                    In some cases, we may identify a requirement to lay
                                    new infrastructure on land that hasn't been adopted or
                                    that falls outside of the adopted land boundaries. In
                                    these instances, we will require a land access or full
                                    land wayleave agreement.
                                </Text>
                            </Col>
                            <Col xl="6">
                                <RadiusImg src={privateRoadsSection} className="w-100"/>
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row className="mt-5 pt-5">
                            <Col xl="6">
                                <Title variant="news" className="pink-color">
                                    What's the<br />
                                    difference between<br />
                                    land access and full<br />
                                    land wayleave<br />
                                    agreement?
                                </Title>
                            </Col>
                            <Col xl="6">
                                <Row>
                                    <Col sm="12" className="mt-5">
                                        <img src={icon1} alt="Netomnia - Landowners/Private Roads" className="mb-3" width="80px"/>
                                        <Text>
                                            <strong>Land access agreements</strong> are required where there
                                            is existing infrastructure on private land that we
                                            need to access to, to install our network.
                                        </Text>
                                        <Text>
                                            These are short-form documents as the work
                                            involved is usually minor and consists of affixing
                                            apparatus to existing poles or feeding cables
                                            through existing chambers.
                                        </Text>
                                    </Col>
                                    <Col sm="12" className="mt-5">
                                        <img src={icon2} alt="Netomnia - Landowners/Private Roads" className="mb-3" width="80px"/>
                                        <Text>
                                            <strong>Land wayleave agreements</strong> are needed to install
                                            new infrastructure on private land, e.g., the
                                            installation of a new pole or chamber to act as a
                                            distribution point for our network.
                                        </Text>
                                        <Text>
                                            These are slightly longer documents, outlining
                                            full clauses for reinstatement, maintenance and
                                            notice of works.
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </PinkContainer>

                <FullWidthSection>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <NarrowBox>
                                    <Title variant="news">When would access<br />be permitted?</Title>
                                    <SmallParagraph>
                                        Once signed, access is permitted immediately.
                                    </SmallParagraph>
                                    <SmallParagraph>
                                        We would only visit when maintenance or
                                        implementation is required however, we always
                                        try our best to keep these to a minimum to
                                        minimise disruption.
                                    </SmallParagraph>
                                </NarrowBox>
                            </Col>
                        </Row>
                    </Container>
                </FullWidthSection>

                <GradientSection>
                    <Container>
                        <Row>
                            <Col lg="10" className="centered">
                                <Title variant="news" className="text-center">What needs to happen next?</Title>
                                <Text className="text-center">
                                    The next steps will be for you to reach out
                                    to our team at <a href="mailto:wayleaves@netomnia.com">wayleaves@netomnia.com</a> to have
                                    any of your questions addressed. 
                                </Text>
                                <Text className="text-center">
                                    It is important that access is granted as quickly as possible
                                    to serve full fibre broadband to the local communities.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </GradientSection>
                
            </PageWrapper>
        </>
    );
};

export default PrivateRoads;
